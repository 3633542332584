import * as Cookies from "js-cookie";

export const setCookie = (access_token, refresh_token) => {
  Cookies.set(
    "token",
    { access_token, refresh_token },
    { expires: 1 },
    { httpOnly: true }
  );
};

export const storeDetails = (user_role, email) => {
  localStorage.setItem("Data", JSON.stringify({ user_role, email }));
};

export const readCookie = () => {
  //const token = true;
  const access_token = Cookies.getJSON("token");
  //const access_token = token;

  if (access_token) {
    return true;
  } else {
    return false;
  }
};

export const removeSession = () => {
  Cookies.remove("token");
  localStorage.removeItem("Data");
};
